import * as React from 'react';
import clsx from 'clsx';
import { Button } from '@mui/material';

import Spinner from 'components/Common/Spinner';
import BlockIcon from '@mui/icons-material/Block';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import useStyles from './styles';

import { EPOS_STATUS, AUDIT_ACTIONS, imageUnavailable, kioskOffline, kioskStarting } from 'const';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

const getLink = (links, type) => links.find((link) => link.protocolType === type);

const screensShowInitial = {
    upperScreen: true,
    mainScreen: true,
};

interface IScreens {
    upperScreen: boolean;
    mainScreen: boolean;
}

const TerminalScreenshot = ({ additionalData, onOpenLinkClick, eposItem, vncPermission }) => {
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);
    let { mainScreen, upperScreen, isScreenShotLoaded } = additionalData;
    const [ screensShow, setScreensShow ] = React.useState<IScreens>(screensShowInitial);
    const links = additionalData.remoteUrls ? additionalData.remoteUrls : [];
    const screenShotsNotAvailable = isScreenShotLoaded && !mainScreen && !upperScreen;

    const vncLink = getLink(links, 'VNC');
    const hasLink = isScreenShotLoaded && vncLink && vncLink.guacamoleUrl;
    const classes = useStyles({});

    React.useEffect(() => {
        setScreensShow({ ...screensShowInitial });
    }, [mainScreen, upperScreen]);

    const handleError = (event) => {
        setScreensShow({ ...screensShow, [event.currentTarget.id]: false });
    };

    return (
        <div className={
            clsx(
                classes.imageBox,
                hasLink && classes.imageBoxLink,
                vncPermission && hasLink && classes.imageBoxVncLink,
                screenShotsNotAvailable && classes.imageBoxScreenNotAvailable,
                !screenShotsNotAvailable || eposItem.status === EPOS_STATUS.OFFLINE && classes.noImageBox)}
        >
            {!isScreenShotLoaded && eposItem.status !== EPOS_STATUS.OFFLINE && <Spinner className={classes.absoluteLoader} />}
            {!screensShow['upperScreen'] && !screensShow['mainScreen'] && eposItem.status !== EPOS_STATUS.OFFLINE && eposItem.status !== EPOS_STATUS.STARTING
                ? (
                    <div className={classes.noImage}>
                        <DesktopAccessDisabledIcon color="disabled" classes={{ root: classes.icon }} />
                        <span className={classes.title}>{translations[imageUnavailable]}</span>
                    </div>)
                : ((eposItem.status === EPOS_STATUS.OFFLINE || eposItem.status === EPOS_STATUS.STARTING)
                    ? (
                        <div className={classes.noImage}>
                            <BlockIcon color="disabled" classes={{ root: classes.icon }} />
                            <span className={classes.title}>
                                {translations[
                                    eposItem.status === EPOS_STATUS.OFFLINE
                                        ? kioskOffline
                                        : kioskStarting
                                ]}
                            </span>
                        </div>)
                    : (
                        <React.Fragment>
                            <div className={classes.screen}>
                                {upperScreen && screensShow['upperScreen']
                                    ? (
                                        <img
                                            id={'upperScreen'}
                                            className={classes.img}
                                            onError={handleError}
                                            src={upperScreen}
                                            alt="screenshot"
                                        />)
                                    : <div className={classes.errorScreen} />
                                }
                            </div>
                            <div className={classes.screen}>
                                {mainScreen && screensShow['mainScreen']
                                    ? (
                                        <img
                                            id={'mainScreen'}
                                            className={classes.img}
                                            onError={handleError}
                                            src={mainScreen}
                                            alt="screenshot"
                                        />)
                                    : <div className={classes.errorScreen} />
                                }
                            </div>
                        </React.Fragment>)
                )
            }
            {vncPermission && hasLink && (
                <Button variant="outlined" onClick={() => onOpenLinkClick(vncLink?.guacamoleUrl, AUDIT_ACTIONS.VNC_CONNECT)}>
                    {translations['hmd-terminal-information-open-screen']}
                </Button>
            )}
        </div>
    );
};

export default TerminalScreenshot;
